import { ComponentProps, FC } from 'react';

import { FlexibleSectionPayload } from 'src/data/Contentful/ContentfulGetFlexibleSection';
import { GetFooterQuery } from 'src/data/Contentful/ContentfulGetFooter';
import { GetPageQuery } from 'src/data/Contentful/ContentfulGetPage';
import { formatFooterProps } from 'src/data/StaticPageGeneration/PageDefaults';
import { HeaderMenuLegacy } from 'src/general/components/HeaderMenu/HeaderMenuLegacy';
import { SeoMeta } from 'src/general/components/SeoMeta/SeoMeta';
import { FooterLegacy } from 'src/general/sections/Footer/FooterLegacy';
import { SectionManager } from 'src/hygiene/sections/SectionManager/SectionManager';

interface Props {
  pageData: GetPageQuery;
  footerData: GetFooterQuery;
  productTotal?: number;
  awardsSectionData?: FlexibleSectionPayload;
  headerData: ComponentProps<typeof HeaderMenuLegacy>['headerData'];
}

export const HygienePageLayout: FC<Props> = ({
  pageData,
  footerData,
  productTotal = 0,
  awardsSectionData,
  headerData,
}) => (
  <>
    <SeoMeta {...pageData.page} />
    <HeaderMenuLegacy headerData={headerData} />
    <SectionManager sections={pageData.page.pageContentCollection.items} productTotal={{ total: productTotal }} />
    <FooterLegacy {...formatFooterProps(footerData)} awardsSectionData={awardsSectionData} />
  </>
);
