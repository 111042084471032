import { FC } from 'react';

import { JsonLD } from './JsonLD';

type Props = {
  data: Object[] | Object;
};

export const JsonLDGlobal: FC<Props> = ({ data }) => {
  return Array.isArray(data) ? (
    <>
      {data.map((item, index) => (
        <JsonLD jsonLD={item} key={`jsonld-${index}`} />
      ))}
    </>
  ) : (
    <JsonLD jsonLD={data} />
  );
};
