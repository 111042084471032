import { FC } from 'react';
import { BreadcrumbList, WithContext } from 'schema-dts';

import { JsonLD } from 'src/general/components/JsonLD/JsonLD';

import { BreadcrumbItem } from './Breadcrumbs.type';

type Props = {
  items: BreadcrumbItem[];
};

export const BreadcrumbsJsonLD: FC<Props> = ({ items }) => {
  const breadcrumbsItems = [...items];

  // Add automatically the homepage at the beginning
  if (!items.length || items[0].url !== '/') {
    breadcrumbsItems.unshift({ label: 'Carma', url: '/' });
  }

  const jsonLD: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbsItems.map((item, index) => ({
      '@type': 'ListItem',
      position: index,
      item: {
        '@id': item.url,
        name: item.label,
      },
    })),
  };

  return <JsonLD jsonLD={jsonLD} />;
};
