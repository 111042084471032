/* eslint-disable no-redeclare */
import { pick } from 'lodash';
import { ReactElement } from 'react';

import { ContentfulPageSection } from 'src/data/Contentful/ContentfulTypes';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';

import {
  SectionComponent,
  SectionComponentWithData,
  SectionComponentWithoutData,
  SectionData,
  SectionKeys,
} from './types';

type NonEmptyArray<T> = [T, ...T[]];

/**
 * The type signatures of renderSection ensure that the function is called with the correct arguments i.e:
 * - Components without data are only called as renderSection(Component, section).
 * - Components with data are called as renderSection(Component, section, productList, [ ...keys ]) with correct keys.
 *
 * Therefore we only need to check for the presence of 'sectionKeys' to confirm we have a component that expects data.
 */
function componentHasData<Section extends ContentfulPageSection, Keys extends SectionKeys<Section>>(
  _component: SectionComponent<Section, Keys>,
  sectionKeys?: NonEmptyArray<Keys>,
): _component is SectionComponentWithData<Section, Keys> {
  return !!sectionKeys;
}

/**
 * Renders a component that conforms to the section component interface.
 * This function ensures that the typing of the data given to the component is correct.
 *
 * If the component does not require data/props, only pass the first two arguments.
 * Otherwise all arguments are required.
 *
 * @param Component - The component to be rendered.
 * @param section - The section definition from Contentful.
 * @param productList - Product data from the products API.
 * @param sectionKeys - Array of keys for the data from 'section' and 'productKeys' that the component requires.
 * @returns The result of calling the given component with specified data.
 */
export function renderSection<Section extends ContentfulPageSection>(
  Component: SectionComponentWithoutData,
  section: Section,
): ReactElement;
export function renderSection<Section extends ContentfulPageSection, Keys extends SectionKeys<Section>>(
  Component: SectionComponentWithData<Section, Keys>,
  section: Section,
  productTotal: Pick<ProductListDataPayload, 'total'>,
  sectionKeys: NonEmptyArray<Keys>,
): ReactElement;
export function renderSection<Section extends ContentfulPageSection, Keys extends SectionKeys<Section>>(
  Component: SectionComponent<Section, Keys>,
  section: Section,
  productTotal?: Pick<ProductListDataPayload, 'total'>,
  sectionKeys?: NonEmptyArray<Keys>,
): ReactElement {
  return componentHasData(Component, sectionKeys) ? (
    <Component
      key={section?.sys.id}
      sectionData={pick<SectionData<Section>, Keys>({ ...section, ...productTotal! }, sectionKeys!)}
    />
  ) : (
    <Component key={section?.sys.id} />
  );
}
