'use client';

import { useFlags } from 'launchdarkly-react-client-sdk';
import Script from 'next/script';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';

import { getSigninUrl } from 'src/authentication/components/SSO/ssoHelper';
import { COOKIE_USER_TOKEN } from 'src/constants';

export const GoogleOneTap: FC = () => {
  const flags = useFlags();
  const [loginUrl, setLoginUrl] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const signinUrl = getSigninUrl(window.location.toString());
      const loginQueryParams = qs.stringify({
        state: btoa(signinUrl),
      });
      const loginUrlBase = new URL('/api/auth/google/one-tap', window.location.toString());
      const url = `${loginUrlBase}?${loginQueryParams}`;
      setLoginUrl(url);
    }
  }, []);

  if (!flags?.uiSsoGoogleOneTap) {
    return null;
  }

  return (
    <>
      <Script src="https://accounts.google.com/gsi/client" key="googleSso" strategy="afterInteractive" />
      {!!loginUrl && (
        <div
          id="g_id_onload"
          data-client_id={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
          data-login_uri={loginUrl}
          data-itp_support="true"
          data-skip_prompt_cookie={COOKIE_USER_TOKEN}
        />
      )}
    </>
  );
};
