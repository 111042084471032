import { UTM_PARAMS } from 'src/constants';

const stripUtmParams = (redirectUrl: string, baseUrl: string): string => {
  const url = new URL(redirectUrl, baseUrl);

  // Strip URL params from the redirect URL
  for (const param of UTM_PARAMS) {
    if (url.searchParams.has(param)) {
      url.searchParams.delete(param);
    }
  }

  return url.toString();
};

export const getSigninUrl = (redirectUrl: string = '') => {
  let signinUrl = '';
  if (typeof window !== 'undefined') {
    const baseUrl = window.location.toString();
    const url = new URL('/api/auth/signin', baseUrl);
    if (redirectUrl) {
      url.searchParams.set('redirectUrl', stripUtmParams(redirectUrl, baseUrl));
    }
    signinUrl = url.toString();
  }

  return signinUrl;
};
