import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { FC } from 'react';

import { getOr } from 'src/utils/getOr';

import { JsonLD } from '../JsonLD/JsonLD';
import { defaultIfEmpty, seoDefault } from './SeoMeta.helpers';

interface Props {
  seoPageTitle?: string;
  seoPageDescription?: string;
  noindex?: boolean;
  socialMetaImage?: {
    url: string;
  };
  canonical?: string;
}

/**
 * Handle all the SEO metadata to set in the page's head
 */
export const SeoMeta: FC<Props> = (props) => {
  const asPath = usePathname();
  const currentUrl = `https://carma.com.au${asPath}`;
  const title = defaultIfEmpty(getOr(props, 'seoPageTitle', ''), seoDefault.title);
  const description = defaultIfEmpty(getOr(props, 'seoPageDescription', ''), seoDefault.description);
  const socialImage = defaultIfEmpty(getOr(props, 'socialMetaImage.url', ''), seoDefault.socialImage);
  const canonicalUrl = defaultIfEmpty(getOr(props, 'canonical', ''), currentUrl);
  const noindex = getOr(props, 'noindex', false);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta key="og:title" property="og:title" content={title} />
        <meta key="description" name="description" content={description} />
        <meta key="og:image" property="og:image" content={socialImage} />
        <meta key="og:url" property="og:url" content={currentUrl} />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="twitter:card" name="twitter:card" content="summary" />
        <link key="canonical" rel="canonical" href={canonicalUrl} />
        {noindex && <meta key="robots" name="robots" content="noindex" />}
      </Head>
      <JsonLD
        jsonLD={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          copyrightYear: new Date().getFullYear(),
          inLanguage: 'en-AU',
          name: title,
          headline: title,
          description,
          url: currentUrl,
          author: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Carma',
          },
        }}
      />
    </>
  );
};
